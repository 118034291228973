<template>
<div>  
    <v-card>
        <v-card-title class="grid-close">
            <span class="headline">Productos para {{priceList.name}}
            </span>

             <v-btn
                style="right:10px; top:10px;"
                icon
                color="primary"
                fab
                absolute
                @click="$emit('cancel')"
                >
                <v-icon dark>fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
        
        <v-container fluid>
            <v-row>
              <v-col v-if="loading" cols="12" sm="6" md="6">
                <v-skeleton-loader
                  ref="skeleton"
                  type="image" 
                  class="mx-auto d-none d-md-block mt-5"
                ></v-skeleton-loader>
              </v-col>
              <v-col v-if="loading" cols="12" sm="6" md="6">
                <v-skeleton-loader
                  ref="skeleton"
                  type="image" 
                  class="mx-auto d-none d-md-block mt-5"
                ></v-skeleton-loader>
              </v-col>
                <v-col cols="12" sm="12" md="12">
                    <a-transfer
                    v-if="!loading"
                    :listStyle="{
                        'height': '300px!important',
                        'width' : '40%',
                        'background' : 'white'
                    }"
                    :data-source="products"
                    :titles="['Todos los productos', 'Productos vinculados']"
                    :target-keys="optionsSelected"
                    :selected-keys="selectedKeys"
                    :show-search="true"
                    @search="handleSearch"
                    :render="item => item.title"
                    :filter-option="filterOption"
                    @change="handleChange"
                    @selectChange="handleSelectChange"
                    :locale= "{ itemUnit: '', itemsUnit: '', notFoundContent: 'Lista vacía', searchPlaceholder: 'Buscar sección' }"
                    />
                </v-col>
            </v-row>
          
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-divider class="mt-3"></v-divider>
                    <v-row justify="end">
                        <v-btn v-if="!loading" @click="updateOptions" color="primary" class="mt-3 save-btn" dark>Aceptar</v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
    </v-card>
</div>
    
</template>

<script>

import transfer from 'ant-design-vue/lib/transfer';
import { db, fb } from "@/firebase";
import { mapActions, mapState } from 'vuex';

export default {
  name: "add-remove-products",
  props: ['priceList', 'productsAdded'],
  components: {
    'a-transfer' : transfer
  },
  data() {
   return {      
      allProductsAddons : [],
      selectedKeys: [],
      optionsSelected : [],
      products : [],
      loading : true
    }
  },
   computed: {
      ...mapState(['selectedBusiness']),
   },

  methods: {

    handleChange(nextTargetKeys, direction, moveKeys) {        
      this.optionsSelected = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {        
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },

    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },

    updateOptions() {
        let changes = []
        this.optionsSelected.reverse()
        this.optionsSelected.forEach((productId, index) => {

            if (this.productsAdded.find(item => item[".key"] == productId)){
                changes.push(this.productsAdded.find(item => item[".key"] == productId))
            } else {
              let product = this.products.find(item => item.key == productId)
              product.priceList = this.priceList.isFixed ? product.price - this.priceList.amount : product.price - (product.price * this.priceList.amount / 100),
              product.discount =  this.priceList.isFixed ? this.priceList.amount : (product.price * this.priceList.amount / 100)
              product.discountPercentage =  this.priceList.isFixed ? parseFloat(this.priceList.amount / product.price * 100).toFixed(2)  : parseFloat(this.priceList.amount.toFixed(2))
              
              changes.push(product)
            }
        })

        this.$emit('success', changes)
    }

  },
  async mounted(){

    this.$binding('products', db.collection(`products`)
        .where("business", "array-contains", this.selectedBusiness[".key"])
        .where("deleted", "==", false)
        .where("addon", "==", false))
    .then( res => {
      this.products = this.products.map( item => {
        let product = item
            product.title = item.name ? item.name : '',
            product.key = item['.key']
            return product  
       })
       this.loading = false
    })

    this.optionsSelected = this.productsAdded.map(item => {
        return item[".key"]
    })
  }
}
</script>