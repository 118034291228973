<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText || "Información actualizada correctamente." }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <h1>
            Listas de precios de
            <span class="font-weight-light">
              {{ getBusinessName }}
            </span>
            <small>
              (Listas disponibles para su uso)
            </small>
          </h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="lists"
            :search="$store.state.search"
            :items-per-page="15"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            :sort-desc="[false, true]"
            item-key="id"
            height="75vh"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100, 250],
            }"
          >
            <template v-slot:[`item.active`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="switchControlChanged(item)"
                  v-model="item.active"
                >
                </v-switch>
              </div>
            </template>

            <template v-slot:[`item.dateStart`]="{ item }">
              {{ item.dateStart | filterDate }}
            </template>

            <template v-slot:[`item.dateEnd`]="{ item }">
              {{ item.dateEnd | filterDate }}
            </template>

            <template v-slot:[`item.amount`]="{ item }">
              <p v-if="item.isFixed">{{ item.amount | currency }}</p>
              <p v-else>{{ `${item.amount}%` }}</p>
            </template>

            <template v-slot:[`item.isFixed`]="{ item }">
              {{ item.isFixed ? "Fijo" : "Porcentual" }}
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn
                @click="editDescount(item)"
                small
                class="ma-2"
                color="primary"
              >
                Editar
              </v-btn>
              <v-btn
                class="ma-2"
                @click="editProducts(item)"
                small
                color="primary"
              >
                productos
              </v-btn>
              <!-- <v-btn class="ml-3 white--text" @click="itemToDelete = item; modalDelete = true" small color="red darken-4">Eliminar</v-btn>   -->
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position:relative;">
                <div
                  style="position:absolute; z-index:999; width:100%; height:100%; display:flex; flex-direction:column;justify-content:center; align-items:center;"
                >
          <v-progress-circular
      :width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>

                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                >
                </v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      persistent
      v-if="modalAddDiscount"
      v-model="modalAddDiscount"
      max-width="50%"
    >
      <add-edit-list
        :type="type"
        :originalPriceLists="itemToEdit"
        @success="handleSuccess"
        @cancel="modalAddDiscount = false"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-if="modalEditProducts"
      v-model="modalEditProducts"
      max-width="80%"
    >
      <edit-products
        :type="type"
        :originalPriceLists="itemToEdit"
        @success="handleSuccess"
        @cancel="modalEditProducts = false"
      />
    </v-dialog>

    <!-- modal delete  -->
    <v-dialog max-width="400" v-if="itemToDelete" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right:10px; top:10px;"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.name }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius:50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import addEditList from "./add-edit-list";
import editProducts from "./edit-products";
export default {
  name: "price-lists",
  mixins: [lottieSettings],
  components: {
    lottie: Lottie,
    addEditList,
    editProducts,
  },
  data() {
    return {
      business: null,
      item: 0,
      randomKey: 0,
      priceLists: [],
      itemToDelete: null,
      itemToEdit: "",
      modalDelete: false,
      statusList: {},
      modalAddDiscount: false,
      modalEditProducts: false,
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Descripción", value: "description" },
        { text: "Tipo de descuento", value: "isFixed" },
        { text: "Monto del descuento", value: "amount" },
        { text: "Activa", value: "active", align: "center", justify: "center" },
        { text: "", value: "options", align: "end", width: "450px" },
      ],
      lists: [],
      loading: true,
      snackbarText: "",
      snackbar: "",
      type: "",
      saving: false,
    };
  },
  watch: {
    selectedBusiness() {
      this.discounts = [];
      this.getBusinessData();
    },
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("LL");
    },
    currency: function(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
  },
  methods: {
    moment,
    getTime() {
      let timeArr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(timeArr[0]),
        parseInt(timeArr[1]) - 1,
        parseInt(timeArr[2]),
        parseInt(timeArr[3]),
        parseInt(timeArr[4]),
        parseInt(timeArr[5]),
        parseInt(timeArr[6])
      );
    },
    handleSuccess(result) {
      this.modalAddDiscount = false;
      this.modalEditProducts = false;
      this.snackbarText = result;
      this.snackbar = true;
    },
    editDescount(item) {
      this.itemToEdit = item;
      this.randomKey = Math.random();
      this.type = "edit";
      this.modalAddDiscount = true;
    },
    editProducts(item) {
      this.modalEditProducts = true;
      this.itemToEdit = item;
      this.randomKey = Math.random();
    },
    switchControlChanged(item) {
      if (item) {
        let data = {};
        data[`priceList${item.listPosition}IsActive`] = item.active;
        this.saving = true;

        db.collection(`businesses`)
          .doc(this.selectedBusiness[".key"])
          .update(data)
          .then(() => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            console.log(err);
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            item.active = !item.active;
          });
      }
    },
    confirmDelete: function() {
      if (this.itemToDelete) {
        db.collection(`businesses/${this.selectedBusiness[".key"]}/priceLists`)
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Elemento borrado";
          })
          .catch(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Occrrió un error, inténtelo nuevamente.";
          });
      }
    },
    getBusinessData() {
      if (!this.selectedBusiness) this.$router.push({ path: "/" });

      this.loading = true;
      this.$store.state.search = "";
      let seq = [1, 2, 3, 4, 5, 6, 7, 8];
      this.lists = [];
      seq.forEach((position, index) => {
        this.lists.push({
          active: this.selectedBusiness.hasOwnProperty(
            `priceList${position}IsActive`
          )
            ? this.selectedBusiness[`priceList${position}IsActive`]
            : false,
          isFixed: this.selectedBusiness.hasOwnProperty(
            `priceList${position}IsFixed`
          )
            ? this.selectedBusiness[`priceList${position}IsFixed`]
            : true,
          amount: this.selectedBusiness[`priceList${position}Amount`]
            ? this.selectedBusiness[`priceList${position}Amount`]
            : 0,
          description: this.selectedBusiness[`priceList${position}Description`]
            ? this.selectedBusiness[`priceList${position}Description`]
            : "",
          name: `Lista ${position}`,
          listPosition: position,
        });
      });
      this.loading = false;
    },
  },
  mounted() {
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "lista de precios";
    this.getBusinessData();
  },
};
</script>

<style scoped>
.theme--light.v-list {
  background: white !important;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.fa-times {
  color: gray;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
.switch-lists {
  display: flex;
  width: 100%;
}
.v-input--selection-controls.v-input {
  flex: auto auto auto !important;
}
</style>
