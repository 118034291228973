<template>
    <div>
        <v-snackbar absolute top :timeout="4000" v-model="snackbar">{{snackbarText}}</v-snackbar>
        <v-overlay class="loading-center" :value="saving">
            <lottie
                :options="defaultOptions"
                :height="300"
                :width="400"
                v-on:animCreated="handleAnimation"
            />
        </v-overlay>

        <v-card>
            <v-card-title class="grid-close">
                <span class="headline"> Productos de {{originalPriceLists.name}}</span>
                <v-btn
                    style="right:10px; top:10px;"
                    icon
                    color="primary"
                    fab
                    absolute
                    @click="$emit('cancel')"
                    >
                    <v-icon dark>fa-times</v-icon>
                </v-btn>
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                   <v-col cols="12" sm="12" md="12">
                       <v-row style="border: 1px solid #80808066;!important; border-radius:40px!important"> 
                            <v-col cols="12" sm="5" md="5">
                                <p>Tipo de descuento</p>
                                <v-autocomplete
                                    v-model="priceList.isFixed"
                                    :items="amountType"
                                    placeholder="Seleccione tipo de monto"
                                    outlined
                                    item-value="value"
                                    item-text="text"
                                    rounded
                                    class="mt-6"
                                />
                            </v-col>

                            <v-col cols="12" sm="5" md="5">
                                <p>Monto del descuento</p>
                                <input class="control-input control-input-number" type="number" placeholder="Ingrese el monto" v-model.number="priceList.amount">
                            </v-col>
                               
                            <v-col cols="12" class="mt-3" sm="2" md="2">
                                <v-btn block @click="newPrices()" class="save-btn mt-10" color="primary">Aplicar</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col style="text-align:end" class="mb-5" cols="12" md="12" sm="12">
                        <v-btn
                            color="primary"
                            class="white--text mt-5"
                            @click="addProductDialog = true; randomKey = Math.random()"
                            >
                            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                            Vincular o desvincular productos
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>    
                  <v-col cols="12" md="12" sm="12">
                        <v-data-table
                            :headers="headers"
                            :items="productsAdded"
                            :items-per-page="5"
                            :loading="loading"
                            :sort-by="['position']"
                            :sort-desc="[false, true]"
                            :footer-props="{
                                itemsPerPageOptions: [10, 25,50,100, 250],
                            }">

                              <template v-slot:item.images="{ item }">
                                <v-avatar>
                                    <img style="object-fit: cover;"
                                        :src="item.images[0] || 'https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df'"
                                        :alt="item.name"
                                    >
                                </v-avatar>
                            </template>
                            <template v-slot:item.active="{ item }">
                                <v-switch v-model="item.active" ></v-switch>
                            </template>

                            <template v-slot:item.name="{ item }">
                                {{item.name}}
                            </template>

                             <template v-slot:item.discount ="{ item }">
                                {{item.priceList - item.price | currency}}
                            </template>

                             <template v-slot:item.discountPercentage ="{ item }">
                                {{item.discountPercentage}} %
                            </template>

                            <template v-slot:item.price ="{ item }">
                                {{item.price | currency}}
                            </template>

                            <template v-slot:item.priceList="{ item }">
                                <!-- {{item.priceList}} -->
                                <v-text-field @keyup="checkPercentage($event, item)" v-model.number="item.priceList" class="new-price" type="number" required></v-text-field>
                            </template>

                                <template v-slot:loading>
                                <div class="ma-5" style="position:relative;">
                                    <div
                                    style="position:absolute; z-index:999; width:100%; height:100%; display:flex; flex-direction:column;justify-content:center; align-items:center;"
                                    >
                                    <lottie
                                        :options="defaultOptions"
                                        :height="300"
                                        :width="400"
                                        v-on:animCreated="handleAnimation"
                                    />
                                    <p
                                        :class="$vuetify.theme.dark?'subtitle-1 primary--text':'subtitle-1 black--text'"
                                    >Cargando Data</p>
                                    </div>
                                    <v-skeleton-loader
                                    ref="skeleton"
                                    type="table-tbody"
                                    class="mx-auto d-none d-md-block"
                                    ></v-skeleton-loader>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-container fluid >
                <v-divider></v-divider>
                <v-row class="pa-0 ma-0">
                    <v-col cols="12" xs="12" sm="12" md="12">
                        <v-row  align="center" justify="end">
                            <v-btn @click="updateList()" class="save-btn mt-4" color="primary">Guardar</v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
        </v-card>

        <v-dialog persistent v-if="addProductDialog" v-model="addProductDialog" max-width="600px">
            <add-remove-products :key="randomKey" :productsAdded="productsAdded" @success="handleSuccess" :priceList="priceList" @cancel="addProductDialog = false"/>
        </v-dialog>

    </div>
</template>

<script>

import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie"
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from 'vuex';
import addRemoveProducts from './add-remove-products'

export default {
    name: "edit-products",
    props: ['type', 'originalPriceLists'],
    components: {
        lottie: Lottie,
        addRemoveProducts,
    },
  data() {
   return {
        animationSpeed: 1.1,
        defaultOptions: {
            animationData: animationData.default,
            loop: true,
            autoplay: true
        },

        snackbar : false,
        snackbarText : '',
        addProductDialog : false,
        saving : false,
        loading : true,
        randomKey : 0,
        priceList : {},
        headers: [
            {
                text: "Código",
                value: "barcode",
            },
            {
                text: "Imágen",
                value: "images",
            },
            {
                text: "Nombre",
                value: "name",
            },
            {
                text: "Precio original",
                value: "price",
                align: 'center'
            },
            {
                text: "% Descuento",
                value: "discountPercentage",
                align: 'center'
            },
            {
                text: "Precio en descuento",
                value: "priceList",
                width: '150px',
                align: 'center'
            },
            {
                text: "Diferencia en precio",
                value: "discount",
                align: 'center'
            },
            {
                text : 'Activo',
                value: "active",
                align: 'center'
            }
        ],

        amountType: [{ text : 'Porcentual', value: false}, { text : 'Fijo', value: true} ],
        businesses : [],
        productsAdded : [],
        productsAddedCopy : [],
        changedPercentage : false
    }
  },

  async mounted(){
    this.priceList = Object.assign({}, this.originalPriceLists)
    this.$binding('productsAdded', db.collection(`products`)
        .where("business", "array-contains", this.selectedBusiness[".key"])
        .where("deleted", "==", false)
        .where("addon", "==", false))
        .then( products => {
            this.productsAdded = this.productsAdded.filter( prod => prod.hasOwnProperty(`priceList${this.priceList.listPosition}`))
            this.productsAdded = this.productsAdded.map( product => {
                let item = product
                item.priceList =  product[`priceList${this.priceList.listPosition}`]
                item.discountPercentage =  parseFloat(100 - (item.priceList / item.price * 100)).toFixed(2)
                item.discount = item.priceList - item.price 
                return item
            })
            this.productsAddedCopy = Object.assign([], this.productsAdded)
            this.loading = false
        })
  },

  filters : {
    currency : function( amount ) {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "HNL",
          minimumFractionDigits: 2
        });
        return formatter.format(amount)
    },
  },

  computed : {
    ...mapState(['selectedBusiness']),

    validate () {
        if ( !this.priceList.amount ) {
            this.snackbarText = 'Debe completar el monto del descuento'
            this.snackbar = true
            return false
        } else {
            return true
        }
    },
    
    getTime() {
        let arr =  moment().tz("America/Tegucigalpa").format("YYYY, MM, DD, HH, mm, ss, 0").split(',')
        return new Date(parseInt(arr[0]), (parseInt(arr[1]) - 1 ), parseInt(arr[2]), parseInt(arr[3]), parseInt(arr[4]), parseInt(arr[5]), parseInt(arr[6]))
    }
  },
  
  methods: {
    moment,
    handleAnimation: function(anim) {
        this.anim = anim;
        anim.setSpeed(this.animationSpeed);
    },

    handleSuccess( products ) {
        this.productsAdded = products        
        this.addProductDialog = false
    },

    newPrices() {
        this.changedPercentage = true
        this.productsAdded.forEach( product => {
            product.discount =  this.priceList.isFixed ? this.priceList.amount : (product.price * this.priceList.amount / 100)
            product.discountPercentage =  this.priceList.isFixed ? parseFloat(this.priceList.amount / product.price * 100).toFixed(2)  : parseFloat(this.priceList.amount.toFixed(2))
            product.priceList = this.priceList.isFixed ? product.price - this.priceList.amount : product.price - (product.price * this.priceList.amount / 100)
        })
    },

    checkPercentage(e, item) {
        item.discountPercentage =  parseFloat(100 - (item.priceList / item.price * 100)).toFixed(2)
        item.discount = item.priceList - item.price 
    },

    async updateList () {
        if ( this.validate ) {
            this.saving = true

            for (let i = 0; i < this.productsAdded.length; i++) {
               await this.changeProductListPrice(this.productsAdded[i])
            }

            for (let i = 0; i < this.productsAddedCopy.length; i++) {
                if ( !this.productsAdded.find( item => item['.key'] == this.productsAddedCopy[i][".key"])) { 
                    await this.removeProductsPriceList(this.productsAddedCopy[i])
                }
            }

            if ( this.changedPercentage ) {
                let data = {}
                data[`isPriceList${this.priceList.listPosition}Fixed`] = this.priceList.isFixed
                data[`priceList${this.priceList.listPosition}Amount`] = this.priceList.amount
                db.collection(`businesses`).doc(this.selectedBusiness[".key"]).update( data )
                .then(ref => {})
            }

            this.saving = false
            this.$emit('success')

        }
    },

    changeProductListPrice( product ) {
        return new Promise((resolve,reject) => { 
            let data = {}
            data[`priceList${this.priceList.listPosition}`] = product.priceList
            db.collection('products').doc(product[".key"]).update( data )
            .then(res => {
                resolve('success')
            })
            .catch( err => {
                reject('failed')
            })
        })
    },

     removeProductsPriceList( product ) {
        return new Promise((resolve,reject) => { 
            let data = {}
            data[`priceList${this.priceList.listPosition}`] = fb.firestore.FieldValue.delete()
            db.collection('products').doc(product[".key"]).update( data )
            .then(res => {
                resolve('success')
            })
            .catch( err => {
                reject('failed')
            })  
        })
    }

  }
}
</script>

<style scoped>
.new-price >>> input  {
    text-align: center;
    width: 50px!important;
}
</style>