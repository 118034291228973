<template>
    <div>
        <v-snackbar absolute top :timeout="4000" v-model="snackbar">
            {{snackbarText}}
        </v-snackbar>
        <v-overlay class="loading-center" :value="saving">
            <lottie
                :options="defaultOptions"
                :height="300"
                :width="400"
                v-on:animCreated="handleAnimation"/>
        </v-overlay>

        <v-card>
            <v-card-title class="grid-close">
                <span class="headline">
                    Editar {{priceList.name}}
                </span>
                <v-btn
                    style="right:10px; top:10px;"
                    icon
                    color="primary"
                    fab
                    absolute
                    @click="$emit('cancel')">
                    <v-icon dark>fa-times</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <p>Descripción</p>
                            <input class="control-input" type="text" placeholder="Ingrese la descripción" v-model="priceList.description">
                        </v-col>

                        <v-col cols="12" md="6">
                            <p>Tipo de descuento</p>
                            <v-autocomplete
                                v-model="priceList.isFixed"
                                :items="amountType"
                                placeholder="Seleccione tipo de monto"
                                outlined
                                item-value="value"
                                item-text="text"
                                rounded
                                class="mt-6"/>
                        </v-col>

                        <v-col cols="12" md="6">
                            <p>Monto del descuento</p>
                            <input class="control-input control-input-number" type="number" placeholder="Ingrese el monto" v-model.number="priceList.amount">
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-container fluid>
                    <v-divider></v-divider>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="6">
                            <v-row  align="center" justify="start">
                                <v-switch v-model="priceList.active" label="Lista activa "></v-switch>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-row  align="center" justify="end">
                                <v-btn v-if="type == 'new'" 
                                    @click="saveList()" 
                                    class="save-btn mt-4" 
                                    color="primary">
                                    Guardar
                                </v-btn>
                                <v-btn v-else 
                                    @click="updateList()" 
                                    class="save-btn mt-4" 
                                    color="primary">
                                    Guardar
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>

        <!-- <v-dialog persistent v-if="addProductDialog" v-model="addProductDialog" max-width="600px">
            <add-remove-products :key="randomKey" @success="handleSuccess" :discount="discount" @cancel="addProductDialog = false"/>
        </v-dialog> -->

    </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie"
import * as animationData from "../../../assets/ochoColor.json";
// import { Parser } from "json2csv"; // Never called JoseV.
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from 'vuex';
// import addRemoveProducts from './add-remove-products' // Never called JoseV
// import { Button } from 'ant-design-vue'; // Never called JoseV.
// import { TimePicker } from 'ant-design-vue'; // Never called JoseV.

export default {
    name: "new-edit-discount",
    props: ['type', 'originalPriceLists'],
    components: {
        lottie: Lottie,
        // newAdSkeleton,
        // addRemoveProducts, // Never called JoseV
        // aTimePicker : TimePicker, // Never called JoseV.
        // aButton : Button, // Never called JoseV.
        // schedule
    },
    data() {
        return {
            animationSpeed: 1.1,
            defaultOptions: {
                animationData: animationData.default,
                loop: true,
                autoplay: true
            },
            snackbar: false,
            snackbarText: '',
            addProductDialog: false,
            dates: [],
            saving: false,
            loading: true,
            randomKey: 0,
            priceList: {
                is: true,
                amount: 0,
                // code: '',
                createdAt: "",
                createdBy: this.$store.state.user['.key'],
                name: '',
                description: '',
                // isCurrent: false,
                deleted: false,
                isFixed: true,
            },
            headers: [
                {
                    text: "Producto",
                    value: "name",
                },
                {
                    text: "Precio original",
                    value: "originalPrice",
                },
                {
                    text: "Nuevo precio",
                    value: "newPrice",
                },
                {
                    value: "options",
                    align: 'end'
                }
            ],
            amountType: [{ text : 'Porcentual', value: false}, { text : 'Fijo', value: true} ],
            businesses : [],
            // startsAt : '', //date
            // endsAt : '', //date
            time : {
                startsAt : '00:00:01',
                endsAt : '23:59:00',
                startsis : false,
                endsis : false
            },
            menuStartsAt : false,
            menuEndsAt : false,
            dates: ['', ''],
            menu : [false, false]
        }
    },
    async mounted(){
        if (this.type == 'edit'){
            this.priceList = Object.assign({}, this.originalPriceLists)
            // this.dates = [moment(new Date(this.originalDiscount.dateStart.seconds * 1000)).format("YYYY-MM-DD"), moment(new Date(this.originalDiscount.dateEnd.seconds * 1000)).format("YYYY-MM-DD")]
            // this.time.startsAt = new Date(this.originalDiscount.dateStart.seconds * 1000).toString().substring(16,24)
            // this.time.endsAt = new Date(this.originalDiscount.dateEnd.seconds * 1000).toString().substring(16,24)
        }
    },

    computed: {
        ...mapState(['selectedBusiness']),

        validate() {
            if (!this.priceList.name  || !this.priceList.amount) {
                this.snackbarText = 'Debe completar el nombre y monto del descuento'
                this.snackbar = true
                return false
            } else {
                return true
            }
        },
        getTime() {
            let timeArr =  moment().tz("America/Tegucigalpa").format("YYYY, MM, DD, HH, mm, ss, 0").split(',')
            return new Date(parseInt(timeArr[0]), (parseInt(timeArr[1]) - 1 ), parseInt(timeArr[2]), parseInt(timeArr[3]), parseInt(timeArr[4]), parseInt(timeArr[5]), parseInt(timeArr[6]))
        },
        generateTimestamp() {
            return new Promise((resolve,reject)=> {
                let date = this.dates[0].split('-')
                let time = this.time.startsAt.split(':')
                this.discount.dateStart =  fb.firestore.Timestamp.fromDate(new Date(parseInt(date[0]), (parseInt(date[1]) - 1 ), parseInt(date[2]), parseInt(time[0]), parseInt(time[1]), parseInt(time[2])))
                date = this.dates[1].split('-')
                time = this.time.endsAt.split(':')
                this.discount.dateEnd = fb.firestore.Timestamp.fromDate(new Date(parseInt(date[0]), (parseInt(date[1]) - 1 ), parseInt(date[2]), parseInt(time[0]), parseInt(time[1]), parseInt(time[2])))
                resolve(true)
            })
        }
    },
    methods: {
        moment,
        handleAnimation: function(anim) {
            this.anim = anim;
            anim.setSpeed(this.animationSpeed);
        },
        // Never called JoseV.
        // handleSuccess(newTarget) {
        //     this.promo.target = newTarget        
        //     this.addProductDialog = false
        // },
        async saveList() {
            if (this.validate) {
                // let generateTimestamp =  await this.generateTimestamp
                this.priceList.createdAt = fb.firestore.Timestamp.fromDate(this.getTime)
                // this.priceList.business = this.selectedBusiness['.key']
                this.priceList.products = []
                this.saving = true

                db.collection(`businesses/${this.selectedBusiness[".key"]}/priceLists`).add( this.priceList )
                    .then(ref => {
                        this.saving = false
                        this.$emit('success', 'Descuento creado exitosamente.')
                    })
                    .catch(err => {
                        this.saving = false
                        this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente."
                        this.snackbar = true
                    })
            }
        },
        async updateList() {
            if (this.validate) {
                // if ( this.originalPriceLists.amount != this.priceList.amount ||  this.originalPriceLists.isFixed != this.priceList.isFixed) {
                //     this.priceList.products.forEach( product => {
                //         product.discount =  this.priceList.isFixed ? this.priceList.amount : (product.originalPrice * this.priceList.amount / 100)
                //         product.discountPercentage =  this.priceList.isFixed ? parseFloat(this.priceList.amount / product.originalPrice * 100).toFixed(2)  : parseFloat(this.priceList.amount.toFixed(2))
                //         product.newPrice = this.priceList.isFixed ? product.originalPrice - this.priceList.amount : product.originalPrice - (product.originalPrice * this.priceList.amount / 100)
                //     })
                // }
                this.saving = true

                let data = {}
                data[`priceList${this.priceList.listPosition}IsActive`] = this.priceList.active
                data[`priceList${this.priceList.listPosition}Name`] = `priceList${this.priceList.listPosition}`
                data[`priceList${this.priceList.listPosition}IsFixed`] = this.priceList.isFixed
                data[`priceList${this.priceList.listPosition}Amount`] = this.priceList.amount
                data[`priceList${this.priceList.listPosition}Description`] = this.priceList.description

                db.collection(`businesses`).doc(this.selectedBusiness[".key"])
                    .update( data )
                    .then(ref => {
                        this.saving = false
                        this.$emit('success')
                    })
                    .catch( err => {
                        this.saving = false
                        this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente."
                        this.snackbar = true
                    })
            }
        },
        // Never called JoseV.
        // handleClose() {
        //     this.time.startsis = false
        //     this.time.endsis = false
        // },
        // Never called JoseV.
        // changeTime(time, index) {
        //     if ( index == 1 ) {
        //         this.time.startsAt =  moment(time).format('HH:mm:ss')
        //     } else {
        //         this.time.endsAt =  moment(time).format('HH:mm:ss')
        //     }
        // }
    }
}
</script>

<style>
.category-color {
    -webkit-appearance: none;
    width: 100%;
    margin-top: 10px;
    border-radius: 50px;
    height: 45px!important;
    cursor: pointer;
}

.category-color::-webkit-color-swatch {
    border-radius: 50px;
    padding: 0;
}

.category-color::-webkit-color-swatch-wrapper {
    border-radius: 50px;
    padding: 0;
}

.select-city {
    border-radius: 50px;
    padding: 0;
}

.control-input {
    border-color: rgba(117, 117, 117, 0.582);
}

.ad-img-promo {
    max-width: 100%;
    min-width: 100%;
    max-height: 250px;
    border-radius: 20px;
    cursor: pointer;
    object-fit: cover;
}

.img-container-promo {
    max-width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 200px;
    cursor: pointer;
    background-color: #92929221;
    border: 1px solid #80808062;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.time-picker .ant-time-picker-input {
    border: 1px solid rgba(0, 0, 0, 0.25)!important;
    border-radius: 50px!important;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    color: black;
    height: 44px;
 }

.upload {
    display: none;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    color: black!important;
    font-weight: bold;
}

.v-tab--is {
    border-bottom: 4px solid #00cdbc!important;
}
</style>
